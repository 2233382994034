import { createStore } from "vuex";

const LOCALE_NAME = "testx.locale";

export default createStore({
    state: {
        theme: "light",
        authInfo: null,
        locale: localStorage.getItem(LOCALE_NAME),
    },
    getters: {
        getLocale: (state) => state.locale,
    },
    mutations: {
        clearAuthInfo: (state) => {
            state.authInfo = null;
        },
        setLocale (state, locale) {
            state.locale = locale;
            localStorage.setItem(LOCALE_NAME, locale);
        },
    },
    actions: {},
    modules: {},
});
