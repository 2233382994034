
import { defineComponent, onMounted, ref, watch } from "vue";

export default defineComponent({
    props: {
        values: {
            type: Array,
            default: () => {
                return [{ value: "", key: "", label: "- no selection -" }];
            },
        },
        name: String,
        modelValue: [String, Boolean],
    },
    setup (props, context) {
        const selectedValue = ref();

        const setValue = (item: any) => {
            selectedValue.value = item.value;
            context.emit("update:model-value", item.value);
        };

        watch(() => props.modelValue, () => {
            selectedValue.value = props.modelValue;
        });

        watch(() => props.values, () => {
            console.log(props.values);
        }, { deep: true });

        onMounted(() => {
            const selected = props.values?.filter((o: any) => o.value === props.modelValue);

            if (selected.length > 0) {
                setValue(selected[0]);
            }
        });

        return { selectedValue, setValue };
    },
});
