import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-487cca51"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "uikit-radio" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (item, idx) => {
      return (_openBlock(), _createElementBlock("label", {
        key: idx,
        class: _normalizeClass({'checked' : _ctx.selectedValue === item.value }),
        onClick: ($event: any) => (_ctx.setValue(item))
      }, [
        (item.label)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(item.label), 1)
            ], 64))
          : (item.key)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.$t(item.key)), 1)
              ], 64))
            : _createCommentVNode("", true)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}