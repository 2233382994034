import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

import "sweetalert2/dist/sweetalert2.min.css";

import resistUIKit from "@/components/UIKit";
import "@vuepic/vue-datepicker/dist/main.css";
import { useComma, useIsNumber } from "@/composables";
import { createI18n } from "vue-i18n";
import ko from "@/locales/ko.json";
import en from "@/locales/en.json";

const app = createApp(App);
const i18n = createI18n({
    legacy: false,
    locale: store.getters.getLocale,
    warnHtmlMessage: false,
    fallbackLocale: "ko-KR",
    messages: { "ko-KR": ko, "en-US": en },
    datetimeFormats: {
        "ko-KR": {
            monthOnly: {
                month: "numeric",
            },
            shortMonthOnly: {
                month: "numeric",
            },
            yearOnly: {
                year: "numeric",
            },
        },
        "en-US": {
            monthOnly: {
                month: "long",
            },
            shortMonthOnly: {
                month: "short",
            },
            yearOnly: {
                year: "numeric",
            },
        },
    },
});

resistUIKit(app);

app.use(store)
    .use(router)
    .use(PerfectScrollbar)
    .use(i18n)
    .mount("#app");

app.config.globalProperties.$filters = {
    comma (value: number | string) {
        return useComma(value);
    },
    isNumber (value: number | string) {
        return useIsNumber(value);
    },
};
