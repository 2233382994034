import Swal, { SweetAlertOptions } from "sweetalert2";
import * as d3 from "d3";
import { ListDefine } from "@/components/UIKit/UIKitListTable.vue";
import { ApiErrorResponse } from "@/api";
import { useI18n } from "vue-i18n";

type easeFunction = (normalizedTime: number) => number;
export type ConditionFunction = (item: any) => boolean;
export type CallbackFunction = (b: boolean | any) => void;

export const REG_EXP_MAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const REG_EXP_LOWER_CASE = /[a-z]/g;
export const REG_EXP_UPPER_CASE = /[A-Z]/g;
export const REG_EXP_NUMBER = /[0-9]/g;
export const REG_EXP_SPECIAL_CHAR = /[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/g;

export function useD3Transition (dur: number, easeFn?: easeFunction) {
    const t = d3.transition().duration(dur);
    if (easeFn) {
        t.ease(easeFn);
    } else {
        t.ease(d3.easeLinear);
    }
    return t;
}

export function useAlert (message: string, options?: SweetAlertOptions): Promise<any> {
    return Swal.fire({
        customClass: "testx-alert",
        title: message,
        confirmButtonText: "확인",
        ...options,
    });
}

export function useConfirm (message: string, options?: SweetAlertOptions): Promise<any> {
    return Swal.fire({
        customClass: "testx-alert",
        title: message,
        confirmButtonText: "예",
        showDenyButton: true,
        denyButtonText: "아니오",
        ...options,
    });
}

export function useApiResponseError (fieldInfo: Array<ListDefine>, response: any) {
    if (response.text) {
        useAlert(response.text);
    } else {
        useAlerApiError(fieldInfo, response);
    }
}

export function useAlerApiError (fieldInfo: Array<ListDefine>, error: any) {
    if (error.response) {
        if (error.response.data) {
            const response = error.response.data as any;
            const errorDetails = response.detail as Array<ApiErrorResponse>;
            if (errorDetails) {
                useAlert(useMakeApiError(fieldInfo, errorDetails));
            } else if (response.text) {
                useAlert(response.text);
            } else {
                console.error(error);
            }
        } else {
            console.error(error);
        }
    }
}

export function useMakeApiError (fieldInfo: Array<ListDefine>, error: Array<ApiErrorResponse>) {
    return error.map((e: any) => {
        const fieldKey = e.loc[e.loc.length - 1];
        const curField = fieldInfo.filter((f: any) => f.key === fieldKey) as Array<any>;
        const { t } = useI18n();
        if (curField.length > 0) {
            return `${t(curField[0].labelKey)} : ${e.msg}`;
        } else {
            return `${e.msg}`;
        }
    }).join("\n");
}

export function useComma (value: any) {
    if (!isNaN(<number>value)) {
        return Number(value).toLocaleString("ko-KR");
    }
}

export function useIsNumber (value: number | string) {
    if (typeof value === "number") {
        return true;
    }

    if (typeof value === "string") {
        const modVal1 = value.replaceAll(".", "").replaceAll(",", "").trim();
        if (modVal1 === "") {
            return false;
        }
        return Number.isInteger(value);
    }

    return false;
}

export function useFormatDate (str: string) {
    try {
        const date = new Date(str);
        if (!isNaN(date.getTime())) {
            return `${date.getFullYear()}.${(date.getMonth() + 1).toString().padStart(2, "0")}.${(date.getDate()).toString().padStart(2, "0")} ${(date.getHours()).toString().padStart(2, "0")}:${(date.getMinutes()).toString().padStart(2, "0")}:${(date.getSeconds()).toString().padStart(2, "0")}`;
        } else {
            return str;
        }
    } catch (e) {
        return str;
    }
}

/**
 * Date to string format "yyyy.mm.dd"
 * @param str
 */
export function useFormatDay (str: string | Date): string {
    try {
        const date = new Date(str);
        if (!isNaN(date.getTime())) {
            return `${date.getFullYear()}.${(date.getMonth() + 1).toString().padStart(2, "0")}.${(date.getDate()).toString().padStart(2, "0")}`;
        } else {
            return str as string;
        }
    } catch (e) {
        return str as string;
    }
}

export function useFormatTime (str: string) {
    try {
        const date = new Date(str);
        if (!isNaN(date.getTime())) {
            return `${(date.getHours()).toString().padStart(2, "0")}:${(date.getMinutes()).toString().padStart(2, "0")}:${(date.getSeconds()).toString().padStart(2, "0")}`;
        } else {
            return str;
        }
    } catch (e) {
        return str;
    }
}

export function useParamFormatDate (date: Date | string, appendTime?: boolean) {
    let _date: Date;
    if (date instanceof Date) {
        _date = date;
    } else {
        _date = new Date(date as string);
    }
    if (appendTime === true) {
        return `${_date.getFullYear()}-${(_date.getMonth() + 1).toString().padStart(2, "0")}-${(_date.getDate()).toString().padStart(2, "0")} 00:00:00`;
    } else {
        return `${_date.getFullYear()}-${(_date.getMonth() + 1).toString().padStart(2, "0")}-${(_date.getDate()).toString().padStart(2, "0")}`;
    }
}

export function useFormatPhoneNumber (number: string) {
    if (typeof number !== "string") {
        return number;
    }
    number = number.replace(/[^0-9]/g, "");
    if (number.length > 11) {
        number = number.substring(0, 11);
    }

    if (number.length === 10) {
        return number
            .replace(/^(\d{0,2})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
            .replace(/-{1,2}$/g, "");
    } else {
        return number
            .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
            .replace(/-{1,2}$/g, "");
    }
}

export function useFormatSecond (second: number) {
    let remains = second;
    const hours = Math.floor(remains / (60 * 60));
    remains = remains - hours * 60 * 60;
    const mins = Math.floor(remains / 60);
    remains = remains - mins * 60;

    return `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}:${remains.toString().padStart(2, "0")}`;
}

export function useCheckNumber (number: number | string, digit?: number) : string {
    if (typeof number === "string") {
        number = number.replace(/[^0-9]/g, "");
    }
    if (digit) {
        return (number + "").substring(0, digit);
    } else {
        return number as string;
    }
}
