/* eslint-disable */
import axios, { AxiosRequestConfig } from "axios";
import { ApiAuth } from "@/api/auth";

export interface ApiErrorResponse {
    loc: Array<string>,
    msg: string,
    type: string
}

export function mapCommonListParam (param: any) {
    const reqParam: any = {};
    reqParam.page = param.page;
    reqParam.per_page = param.itemsPerPage;
    reqParam.s_key = param.searchWord;
    if (param.sort) {
        reqParam.sort = param.isAsc ? param.sort : `-${param.sort}`;
    }

    return reqParam;
}

export function requestGet (url: string, param?: any) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: param,
        }).then(response => {
            if (response.status === 200) {
                resolve(response.data);
            } else {
                // on error occurred
            }
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
}

export function requestPost (url: string, param?: any, config?: AxiosRequestConfig) {
    return new Promise((resolve, reject) => {
        axios.post(url, param, config).then(response => {
            if (response.status === 200) {
                resolve(response.data);
            } else {
                // on error occurred
            }
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
}

export function requestPut (url: string, param?: any, config?: AxiosRequestConfig) {
    return new Promise((resolve, reject) => {
        axios.put(url, param, config).then(response => {
            if (response.status === 200) {
                resolve(response.data);
            } else {
                // on error occurred
            }
        }).catch(error => {
            console.log(error);
            reject(error);
        });
    });
}

let spinnerTimer = -1;
axios.interceptors.request.use(async config => {
        if (spinnerTimer < 0 && !config.url?.endsWith("/dashboard/")) {
            spinnerTimer = setTimeout(() => {
                document.getElementById("app")?.classList.add("loading");
            }, 500);
        }
        if (!axios.defaults.headers.common.Authorization) {
            const tokenInfo = ApiAuth.getTokenInfo();
            if (tokenInfo !== null) {
                axios.defaults.headers.common.Authorization = `Bearer ${tokenInfo.access_token}`;
            }
        }

        if (!config.headers.Authorization && axios.defaults.headers.common.Authorization) {
            config.headers.Authorization = axios.defaults.headers.common.Authorization;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    });

axios.interceptors.response.use(response => {
        if (spinnerTimer > 0) {
            clearTimeout(spinnerTimer);
            spinnerTimer = -1;
        }
        document.getElementById("app")?.classList.remove("loading");
        return response;
    },
    async (error: any) => {
        if (spinnerTimer > 0) {
            clearTimeout(spinnerTimer);
            spinnerTimer = -1;
        }

        document.getElementById("app")?.classList.remove("loading");
        const failedAxiosConfig = error.config;

        if (error.response && error.response.status === 401 && !failedAxiosConfig._retry) {
            failedAxiosConfig._retry = true;

            const newToken = await ApiAuth.reIssueToken(error.config);
            axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;
            failedAxiosConfig.headers.Authorization = `Bearer ${newToken}`;

            return axios(failedAxiosConfig);
        }
        return Promise.reject(error);
    });

export default axios;
