<template>
    <VueDatePicker
        v-model="boundValue"
        :class="{'list-input': inputType === 'list', 'form-input': inputType === 'form'}"
        :clearable="false"
        :enable-time-picker="enableTimePicker"
        :format="format"
        :month-picker="monthPicker"
        :multi-calendars=" multiCalendars"
        :position="position"
        :range="range"
        :teleport="teleport"
        auto-apply
        text-input
    >
        <template #input-icon>
            <img class="input-slot-image" src="@/assets/images/uikit/icon_datepicker.svg"/>
        </template>
    </VueDatePicker>
</template>

<script setup type="ts">
import { defineProps, ref, watch } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
// eslint-disable-next-line
const emit = defineEmits();
// eslint-disable-next-line
const props = defineProps({
    monthPicker: Boolean,
    range: Boolean,
    multiCalendars: Boolean,
    enableTimePicker: Boolean,
    format: {
        type: String,
        default: "yyyy.MM.dd",
    },
    modelValue: {
        type: [Object, Array, String],
    },
    inputType: {
        type: String,
        default: "",
    },
    position: {
        type: String,
        default: "center",
    },
    teleport: {
        type: [Boolean, String],
        default: null,
    },
});

const boundValue = ref(props.modelValue);
watch(boundValue, () => {
    emit("update:model-value", boundValue.value);
});
watch(() => props.modelValue, () => {
    boundValue.value = props.modelValue;
});

</script>

<style lang="scss">
@import "@/scss/variables";

:root {
    --dp-font-size: 14px;
}

.dp__main {
    width: auto;
    //min-width: 127px;
    display: inline-block;
}

.dp__input_wrap {
    width: auto;
}

.dp__input {
    //height: 36px;
    height: 30px;
    //line-height: 36px;
    line-height: 30px;
    border-radius: 15px;
    background-color: $color-light-2;

    &[readonly] {
        background-color: #ffffff;
        border: 1px solid $color-light-2;
    }
}

.dp__input_icon_pad {
    padding: 0 36px 0 0;
    text-align: center;
}

.dp__input_icon {
    left: auto;
    right: 20px;
}

.dp__theme_light {
    --dp-text-color: $color-tl-font;
    --dp-border-color: transparent;
    --dp-border-color-hover: transparent;
}

.list-input {
    .dp__input_wrap {
        text-align: left;
    }

    .dp__input {
        @extend %list-input-style;
        text-align: center;
    }

    .dp__input_icon_pad {
        width: calc(100% - 25px);
        padding: 0;
    }

    .dp__input_icon {
        right: 5px;
    }
}

.form-input {
    .dp__input {
        height: 36px;
        line-height: 36px;
    }
}
</style>
