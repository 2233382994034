
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
    props: {
        options: {
            type: Array,
            default: () => {
                return [{ value: "", key: "", label: "- no selection -" }];
            },
        },
        width: {
            type: [Number, String],
            default: 145,
        },
        modelValue: [Number, String],
        type: String,
        isListInput: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        bordered: {
            type: Boolean,
            default: false,
        },
        backgroundColor: {
            type: String,
            default: "#ebf0fa",
        },
    },
    setup (props, context) {
        const { t } = useI18n();
        const elOptionBox = ref<HTMLDivElement>();
        const elOptions = ref<HTMLDivElement>();

        const isShowOptions = ref();
        let optionHideTimer = -1;
        const selectLabel = computed(() => {
            const opt: Array<any> = props.options?.filter((o: any) => o.value === props.modelValue);

            if (opt && opt.length === 1) {
                if (opt[0].label) {
                    return opt[0].label;
                } else if (opt[0].key) {
                    return t(opt[0].key);
                }
            }
            return "";
        });

        const styleWidth = computed(() => {
            if (props.isListInput) {
                return "100%";
            }
            if (typeof props.width === "number") {
                return props.width + "px";
            } else {
                return props.width;
            }
        });

        const showOptions = () => {
            if (props.readonly) {
                return;
            }
            if (optionHideTimer > 0) {
                clearTimeout(optionHideTimer);
            }
            isShowOptions.value = true;
            const optListHeight = elOptionBox.value?.getBoundingClientRect().height;

            if (elOptions.value && optListHeight) {
                elOptions.value.style.height = `${(optListHeight > 200 ? 200 : optListHeight) + 2}px`;
            }
        };

        const hideOptions = () => {
            if (optionHideTimer > 0) {
                clearTimeout(optionHideTimer);
            }
            optionHideTimer = setTimeout(() => {
                isShowOptions.value = false;
                if (elOptions.value) {
                    elOptions.value.style.height = "0";
                }
            }, 200);
        };

        const changeValue = (opt: any) => {
            context.emit("update:model-value", opt.value);
            isShowOptions.value = false;
            context.emit("onChange", opt.value);
        };

        const setValue = (value: any) => {
            isShowOptions.value = false;
        };

        watch(() => props.modelValue, () => {
            setValue(props.modelValue);
        });

        watch(() => props.options, () => {
            setValue(props.modelValue);
        });

        onMounted(() => {
            setValue(props.modelValue);
        });

        return {
            isShowOptions,
            styleWidth,
            showOptions,
            hideOptions,
            selectLabel,
            changeValue,
            elOptions,
            elOptionBox,
        };
    },
});
