import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { ApiAuth, AUTH_INFO_NAME } from "@/api/auth";
import store from "../store/index";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/home",
        name: "home",
        component: () => import("../views/homeView.vue"),
        meta: { title: "", layout: "service" },
    },
    {
        path: "/service",
        name: "Service",
        component: () => import("../views/service/serviceView.vue"),
        meta: { layout: "service" },
        // children: [
        // { path: "product/jig", name: "JIG", component: () => import("../views/service/productJig.vue") },
        // { path: "product/scanner", name: "Scanner", component: () => import("../views/service/productScanner.vue") },
        // { path: "product/cloud", name: "Cloud", component: () => import("../views/service/productCloud.vue") },
        // { path: "case", name: "Case", component: () => import("../views/service/caseView.vue") },
        // ]
    },
    { path: "/service/product/jig", name: "JIG", component: () => import("../views/service/productJig.vue"), meta: { layout: "service" } },
    { path: "/service/product/scanner", name: "Scanner", component: () => import("../views/service/productScanner.vue"), meta: { layout: "service" } },
    { path: "/service/product/cloud", name: "Cloud", component: () => import("../views/service/productCloud.vue"), meta: { layout: "service" } },
    { path: "/service/case", name: "Case", component: () => import("../views/service/caseView.vue"), meta: { layout: "service" } },
    {
        path: "/logout",
        name: "Logout",
        component: () => import("../views/login/LogoutView.vue"),
        meta: { title: "Login", noLayoutHeader: true },
    },
    {
        path: "/request_token",
        name: "Request token",
        component: () => import("../views/login/RequestToken.vue"),
        meta: { title: "Login", noLayoutHeader: true },
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("../views/dashboard/DashboardView.vue"),
        meta: { title: "Dashboard", noLayoutHeader: true },
    },
    {
        path: "/test",
        name: "page.testManage.gnbTitle",
        redirect: "/test/status",
        children: [
            { path: "registration", name: "page.testRegistration.gnbTitle", component: () => import("../views/test/OrderRegistration.vue"), meta: { crud: "C" } },
            { path: "status", name: "page.testStatus.gnbTitle", component: () => import("../views/test/TestStatus.vue") },
            { path: "result", name: "page.testResult.gnbTitle", component: () => import("../views/test/TestResult.vue") },
            { path: "orderDetail", name: "page.testStatusDetail.title", component: () => import("../views/test/OrderDetail.vue"), meta: { crud: "R" } },
        ],
    },
    {
        path: "/test_info",
        name: "page.testInfo.gnbTitle",
        redirect: "/test_info/product",
        children: [
            { path: "product", name: "page.product.gnbTitle", component: () => import("../views/test_info/ProductManage.vue") },
            { path: "firmware", name: "page.firmware.gnbTitle", component: () => import("../views/test_info/FirmwareManage.vue") },
            { path: "test_set", name: "page.testSet.gnbTitle", component: () => import("../views/test_info/TestSetManage.vue") },
            { path: "profile", name: "page.profile.gnbTitle", component: () => import("../views/test_info/ProfileManage.vue") },
        ],
    },
    {
        path: "/facility",
        name: "page.facilityManage.gnbTitle",
        redirect: "/facility/jig",
        children: [
            { path: "jig", name: "page.jig.gnbTitle", component: () => import("../views/facility/JigManage.vue") },
            { path: "manager", name: "page.manager.gnbTitle", component: () => import("../views/facility/ManagerManage.vue") },
            { path: "line", name: "page.line.gnbTitle", component: () => import("../views/facility/LineManage.vue") },
        ],
    },
    {
        path: "/admin",
        name: "",
        redirect: "/admin/users/list",
        children: [
            {
                path: "users",
                name: "admin.page.user.gnbTitle",
                redirect: "/admin/users/list",
                children: [
                    { path: "list", name: "admin.page.userManage.gnbTitle", component: () => import("../views/admin/users/UserList.vue") },
                ],
            },
            {
                path: "data",
                name: "",
                redirect: "/admin/data/company",
                children: [
                    { path: "company", name: "admin.page.companyManage.gnbTitle", component: () => import("../views/admin/data/CompanyManage.vue") },
                    { path: "partner", name: "admin.page.partnerManage.gnbTitle", component: () => import("../views/admin/data/PartnerManage.vue") },
                ],
            },
            {
                path: "payment",
                name: "",
                redirect: "/admin/payment/usage",
                children: [
                    { path: "usage", name: "admin.page.usage.gnbTitle", component: () => import("../views/admin/payment/UsageList.vue") },
                    { path: "bill", name: "admin.page.bill.gnbTitle", component: () => import("../views/admin/payment/BillList.vue") },
                    { path: "method", name: "admin.page.paymentMethod.gnbTitle", component: () => import("../views/admin/payment/PaymentMethod.vue") },
                    { path: "plan", name: "admin.page.paymentPlan.gnbTitle", component: () => import("../views/admin/payment/PaymentPlan.vue") },
                ],
            },
        ],
    },
    {
        path: "/promotion",
        name: "testX Promotion",
        component: () => import("../views/promotion/PromotionView.vue"),
        meta: { noLayoutHeader: true },
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: () => {
            if (localStorage.getItem(AUTH_INFO_NAME)) {
                return "/dashboard";
            } else {
                return "/home";
            }
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            console.log(savedPosition);
            return savedPosition;
        } else {
            if (to.hash) {
                return {
                    el: to.hash,
                    behavior: "smooth",
                };
            } else {
                return { top: 0 };
            }
        }
    },
});

router.beforeEach((to, from, next) => {
    // 관리자 페이지 권한 검사
    if (to.path.indexOf("/admin") >= 0) {
        if (!store.state.authInfo) {
            const authInfoStr = localStorage.getItem(AUTH_INFO_NAME);
            if (authInfoStr) {
                ApiAuth.getUserInfo().then((response: any) => {
                    store.state.authInfo = response;
                    if (response.roles.includes("tenant-admin") || response.roles.includes("super-admin")) {
                        next();
                    } else {
                        next({ path: "/" });
                    }
                });
            } else {
                next({ path: "/logout" });
            }
        } else {
            if ((store.state.authInfo as any).roles.includes("tenant-admin") || (store.state.authInfo as any).roles.includes("super-admin")) {
                next();
            } else {
                next({ path: "/" });
            }
        }
    } else {
        next();
    }
});

export default router;
